import instance from 'api/instance';
import {
  AddDailyReportFile,
  AddScheduleFile,
  AddScheduleFileData,
  CheckoutParticipant,
  CheckoutSchedule,
  CloseSchedule,
  CreateSchedule,
  DeleteDailyReportFile,
  DeleteSchedule,
  DeleteScheduleFile,
  EditDailyLog,
  EditDailyReport,
  EditSchedule,
  GetDailyLog,
  GetDailyLogs,
  GetDailyReport,
  GetEmployeesForSchedule,
  GetForemanReports,
  GetSchedule,
  GetSchedules,
  GetScheduleStages,
  GetSharedForemanReports,
  GetSharedSchedule,
  GetSupervisors,
  GetWorkerAttendanceStatuses,
  SendSchedule,
  SendScheduleToSupervisors,
  SendWorkRequests,
} from './types';

export const getSchedules: GetSchedules = (body) => {
  return instance.post('/exec?action=get_schedules', body);
};

export const getSchedule: GetSchedule = (id: number) => {
  return instance.post('/exec?action=get_schedule', {
    unique: id,
  });
};
export const getSharedSchedule: GetSharedSchedule = (id: string) => {
  return instance.post('/exec?action=get_scheduleByUuid', {
    uuid: id,
  });
};

export const getForemanReports: GetForemanReports = (id: number) => {
  return instance.get(`/exec?action=get_ReportsFull&p=${id}`);
};
export const getSharedForemanReports: GetSharedForemanReports = (id: string) => {
  return instance.post('/exec?action=get_ReportsFullByUuid', {
    uuid: id,
  });
};

export const getSupervisors: GetSupervisors = () => {
  return instance.get('/exec?action=get_supervisors');
};

export const addScheduleFileData: AddScheduleFileData = (body) => {
  return instance.post('/exec?action=add_scheduleFileData', body);
};

export const addScheduleFile: AddScheduleFile = (body) => {
  return instance.post('/exec?action=post_scheduleFile', body);
};

export const createSchedule: CreateSchedule = (body) => {
  return instance.post('/exec?action=create_schedule', body);
};

export const editSchedule: EditSchedule = (body) => {
  return instance.post('/exec?action=edit_schedule', body);
};

export const deleteScheduleFile: DeleteScheduleFile = (p) => {
  return instance.post('/exec?action=delete_scheduleFile', { p });
};

export const getScheduleStages: GetScheduleStages = () => {
  return instance.get('/exec?action=get_scheduleStages');
};

export const sendScheduleToSuperVisor: SendSchedule = (unique) => {
  return instance.post('/exec?action=send_scheduleToSupervisors', { unique });
};

export const closeSchedule: CloseSchedule = (unique) => {
  return instance.post('/exec?action=close_schedule', { unique });
};

export const checkoutSchedule: CheckoutSchedule = (unique) => {
  return instance.post('/exec?action=checkOut_schedule', { unique });
};
export const checkoutParticipant: CheckoutParticipant = (unique) => {
  return instance.post('/exec?action=checkOut_participant', { unique });
};

export const sendWorkRequests: SendWorkRequests = (unique) => {
  return instance.post('/exec?action=send_workRequests', { unique });
};

export const sendScheduleToSupervisors: SendScheduleToSupervisors = (unique) => {
  return instance.post('/exec?action=send_scheduleToSupervisors', { unique });
};

export const deleteSchedule: DeleteSchedule = (unique) => {
  return instance.post('/exec?action=delete_schedule', { unique });
};

export const getDailyReport: GetDailyReport = (body) => {
  return instance.post('/exec?action=get_daily_reportWeb', body, {
    responseType: body?.download ? 'blob' : 'json',
  });
};

export const editDailyReport: EditDailyReport = (body) => {
  return instance.post('/exec?action=edit_daily_ReportWeb', body);
};

export const addDailyReportFile: AddDailyReportFile = (body) => {
  return instance.post('/exec?action=add_daily_files', body);
};

export const deleteDailyReportFile: DeleteDailyReportFile = (unique: number) => {
  return instance.post('/exec?action=delete_daily_file', {
    unique,
  });
};

export const getWorkerAttendanceStatuses: GetWorkerAttendanceStatuses = () => {
  return instance.get('/exec?action=get_workerAttendanceStatuses');
};

export const getDailyLogs: GetDailyLogs = (unique: number) => {
  return instance.post('/exec?action=get_daily_logsWeb', {
    unique,
  });
};

export const getDailyLog: GetDailyLog = (unique: number) => {
  return instance.post('/exec?action=get_daily_logWeb', {
    unique,
  });
};

export const editDailyLog: EditDailyLog = (body) => {
  return instance.post('/exec?action=edit_daily_logWeb', body);
};

export const getEmployeesForSchedule: GetEmployeesForSchedule = (body) => {
  return instance.post('/exec?action=get_employees_for_schedule', body);
};
