import { FormControl, FormLabel, Stack } from '@mui/material';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { AddProjectModalProps, AddProjectModalRef } from './types';

import { ReactComponent as MoneysIcon } from 'assets/icons/moneys.svg';
import { ReactComponent as TimerIcon } from 'assets/icons/timer.svg';

import { projectsService } from 'api';
import { AddProjectData, AddProjectError, EditProjectData } from 'api/projects/types';
import { formatDate } from 'app/helpers/formatDate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CompanyModel } from 'app/models/CompanyModel';
import PredictionModel from 'app/models/PredictionModel';
import { ProjectModel, ProjectStatus } from 'app/models/ProjectModel';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import './AddProjectModal.sass';
import { getPaymentOptions } from 'store/slices/projects';
import { MapModalRef } from '../MapModal/types';
import MapModal from '../MapModal/MapModal';
import FormCompaniesSelect from '../../Inputs/Filters/FormCompaniesSelect';
import { getFCompanies } from 'store/slices/filters';

interface SubmitData {
  name: string;
  paymentOptionId: string;
  address: PredictionModel | null;
  company: CompanyModel | null;
  status: ProjectStatus | null;
  from: string;
  to: string;
  addressDescription: string;
}

const AddProjectModal: React.ForwardRefRenderFunction<AddProjectModalRef, AddProjectModalProps> = (
  { getData, successCallback },
  ref
) => {
  const dispatch = useAppDispatch();
  const { control, register, setValue, handleSubmit, reset, setError, getValues, clearErrors } = useForm<SubmitData>();

  const { statuses, paymentOptions } = useAppSelector((state) => state.projects);
  const { companies } = useAppSelector((state) => state.filters);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentProject, setCurrentProject] = useState<ProjectModel | null>(null);
  const [currentCompany, setCurrentCompany] = useState<CompanyModel | null>(null);
  const mapModalRef = useRef<MapModalRef>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: (project) => {
        setCurrentProject(null);
        if (project) setCurrentProject(project);

        dispatch(getFCompanies({ reset: true }));
        dispatch(getPaymentOptions());
        setOpen(true);
      },
      hide: () => setOpen(false),
    }),
    [dispatch]
  );

  useEffect(() => {
    if (!currentProject || !companies.data.length) return reset();
    const { name, address, uniqueC, statusId, startDate, endDate } = currentProject;
    const currentCompany = companies.data.find((c) => c.unique === uniqueC)!;
    setValue('name', name);
    setValue('address', {
      description: address[0].description,
      location: {
        longitude: address[0].longitude,
        latitude: address[0].latitude,
      },
    });
    setValue('addressDescription', address[0].description);
    setValue('company', currentCompany);
    setValue('status', statuses?.find((s) => s.id === statusId)!);
    setValue('from', `${new Date(formatDate({ date: startDate }) ?? '')}`);

    if (endDate) {
      setValue('to', `${new Date(formatDate({ date: endDate }) ?? '')}`);
    }
    setCurrentCompany(currentCompany);
  }, [currentProject, companies.data]);

  const addProject: SubmitHandler<SubmitData> = async (data) => {
    if (!data.address) return;

    try {
      setLoading(true);

      const project: AddProjectData = {
        name: data.name,
        uniqueC: data.company?.unique!,
        address: {
          description: data.address.description,
          latitude: data.address.location.latitude,
          longitude: data.address.location.longitude,
        },
        statusId: data.status?.id!,
        paymentOptionId: data.paymentOptionId,
      };

      if (data.to) project.to = dayjs(data.to).format('DD/MM/YYYY');
      if (data.from) project.from = dayjs(data.from).format('DD/MM/YYYY');

      const { unique } = await projectsService.addProject(project).then((res) => res.data);

      if (successCallback) {
        successCallback(unique);
      }

      getData && getData();
      onClose();
    } catch (e) {
      const err = e as AxiosError;
      const errorText = (err.response?.data as AddProjectError)?.errorText;
      if (!!errorText?.length) setError('name', { message: errorText });
    } finally {
      setLoading(false);
    }
  };

  const editProject: SubmitHandler<SubmitData> = async (data) => {
    if (!currentProject || !data.address) return;

    try {
      setLoading(true);

      const project: EditProjectData = {
        unique: currentProject.unique,
        name: data.name,
        uniqueC: data.company?.unique!,
        address: {
          description: data.address.description,
          latitude: data.address.location.latitude,
          longitude: data.address.location.longitude,
        },
        statusId: data.status?.id!,
        paymentOptionId: data.paymentOptionId,
      };

      if (data.to) project.to = dayjs(data.to).format('DD/MM/YYYY');
      if (data.from) project.from = dayjs(data.from).format('DD/MM/YYYY');

      await projectsService.editProject(project);

      getData && getData();
      setOpen(false);
    } catch (e) {
      const err = e as AxiosError;
      const errorText = (err.response?.data as AddProjectError)?.errorText;
      if (!!errorText?.length) setError('name', { message: errorText });
    } finally {
      setLoading(false);
    }
  };

  const confirmAddress = (address: { latitude: number; longitude: number; description: string }) => {
    setValue('addressDescription', address.description);
    setValue('address', {
      description: address.description,
      location: {
        latitude: address.latitude,
        longitude: address.longitude,
      },
    });

    clearErrors('addressDescription');
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (date === null) {
      setValue('from', dayjs(new Date()).format('DD MMM, YYYY'));
    } else {
      const endDate = getValues('to');

      if (dayjs(date).isAfter(endDate)) {
        setValue('to', dayjs(date).format('DD MMM, YYYY'));
      }
    }
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (date === null) {
      setValue('to', dayjs(new Date()).format('DD MMM, YYYY'));
    } else {
      const startDate = getValues('from');

      if (dayjs(date).isBefore(startDate)) {
        setValue('from', dayjs(date).format('DD MMM, YYYY'));
      }
    }
  };

  const onClose = () => {
    reset({
      name: '',
      paymentOptionId: '',
      address: null,
      company: null,
      status: null,
      from: '',
      to: '',
      addressDescription: '',
    });

    setOpen(false);
    setCurrentCompany(null);
  };

  return (
    <>
      <UIModal
        open={open}
        onClose={onClose}
        classes={{
          root: 'add-project-modal',
          paper: 'add-project-modal__paper',
        }}
      >
        <SubmitModalLayout
          title={currentProject ? 'Edit Project' : 'Add Project'}
          onClose={onClose}
          onSubmit={handleSubmit(currentProject ? editProject : addProject)}
          loading={loading}
        >
          <div className='add-project-modal__inputs'>
            {/* <FormControl className='add-project-modal__row'>
              <FormLabel required>Name</FormLabel>
              <FormTextInput name='name' control={control} rules={{ required: 'Required field' }} />
            </FormControl> */}
            <FormControl className='add-project-modal__row'>
              <FormLabel required>Address</FormLabel>
              <FormTextInput
                control={control}
                name='addressDescription'
                onClick={() => mapModalRef.current?.show(currentProject?.address[0])}
                rules={{ required: 'Required field' }}
              />
            </FormControl>
            {companies.data && (
              <FormControl className='add-project-modal__row'>
                <FormLabel required>Company</FormLabel>
                <FormCompaniesSelect
                  defaultValue={currentCompany}
                  name='company'
                  control={control}
                  rules={{ required: 'Required field' }}
                />
              </FormControl>
            )}
            {statuses && (
              <FormControl className='add-project-modal__row'>
                <FormLabel required>Status {statuses.find((o) => o.id === currentProject?.statusId)?.id}</FormLabel>
                <FormSelectInput
                  control={control}
                  name='status'
                  options={statuses}
                  getOptionLabel={(o) => o.name}
                  rules={{ required: 'Required field' }}
                />
              </FormControl>
            )}
            <Stack direction='row' spacing={2}>
              <FormControl className='add-project-modal__row'>
                <FormLabel>Start Date</FormLabel>
                <FormDateInput control={control} name='from' value={new Date()} onChange={handleChangeStartDate} />
              </FormControl>
              <FormControl className='add-project-modal__row'>
                <FormLabel>End Date</FormLabel>
                <FormDateInput control={control} name='to' value={new Date()} onChange={handleChangeEndDate} />
              </FormControl>
            </Stack>
            {paymentOptions && (
              <FormControl className='add-project-modal__row'>
                <FormLabel required>Payment Option</FormLabel>
                <Stack direction='row' spacing={2}>
                  {[...paymentOptions].reverse().map((payment, index) => (
                    <div className='add-project-modal__radio' key={index}>
                      <input
                        type='radio'
                        id={payment.id}
                        className='add-project-modal__radio-input'
                        value={payment.id}
                        defaultChecked={
                          currentProject?.paymentOptionId ? currentProject?.paymentOptionId === payment.id : index === 0
                        }
                        {...register('paymentOptionId')}
                      />
                      <label htmlFor={payment.id} className='add-project-modal__radio-label'>
                        {payment.id === 'fixedPrice' && <MoneysIcon />}
                        {payment.id === 'hourlyRate' && <TimerIcon />}
                        {payment.name}
                      </label>
                    </div>
                  ))}
                </Stack>
              </FormControl>
            )}
          </div>
        </SubmitModalLayout>
      </UIModal>
      <MapModal ref={mapModalRef} onSubmit={confirmAddress} />
    </>
  );
};

export default React.forwardRef(AddProjectModal);
