import { ReactComponent as MapLocationIcon } from 'assets/icons/primary/map-location.svg';
import React, { useImperativeHandle, useState } from 'react';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import UIModal from 'shared/ui/UIModal/UIModal';
import { MapModalProps, MapModalRef } from '../MapModal/types';
import './MapModal.sass';

import {
  AdvancedMarker,
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  MapMouseEvent,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { getAddress } from 'api/utils';
import MapHandler from '../MapModal/MapHandler/MapHandler';
import MapModalSearchInput from '../MapModalSearchInput/MapModalSearchInput';

const API_KEY = 'AIzaSyArvNHmkKvB4iiDCUmdrlqysdzIaxKwBg8';
const MapModal: React.ForwardRefRenderFunction<MapModalRef, MapModalProps> = ({ onSubmit }, ref) => {
  const [open, setOpen] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [center, setCenter] = useState({
    lat: 40.73061,
    lng: -73.935242,
  });
  const [description, setDescription] = useState('');

  useImperativeHandle(
    ref,
    () => ({
      show: (address) => {
        if (address) {
          setCenter({
            lat: address.latitude,
            lng: address.longitude,
          });
          setDescription(address.description);
        }

        setOpen(true);
      },
      hide: () => setOpen(false),
    }),
    []
  );

  const getAddressHandle = async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    try {
      setMapLoading(true);
      setCenter({ lat: latitude, lng: longitude });

      const { data } = await getAddress({ longitude, latitude });
      setDescription(data.data.description);
    } finally {
      setMapLoading(false);
    }
  };
  const handleConfirm = () => {
    onSubmit({
      latitude: center.lat,
      longitude: center.lng,
      description: description,
    });

    setOpen(false);
  };

  const fetchUserLocation = () => {
    setMapLoading(true);
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setCenter({ lat: latitude, lng: longitude });
              getAddressHandle({ latitude, longitude });
            },
            () => {
              // If the user denies the location request
              alert('Location permission was denied. Please enable it in your browser settings to use this feature.');
            }
          );
        } else if (result.state === 'denied') {
          // Inform the user they need to enable location services manually
          alert('Location permission was denied. Please enable it in your browser settings to use this feature.');
        }
      });
    } else {
      // Geolocation is not supported by this browser
      console.error('Geolocation is not supported by your browser.');
    }
    setMapLoading(false);
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'map-modal',
        paper: 'map-modal__paper',
      }}
    >
      <div style={{ width: '100%', height: '635px' }}>
        <APIProvider apiKey={API_KEY} solutionChannel='GMP_devsite_samples_v3_rgmautocomplete'>
          <Map
            defaultCenter={center}
            defaultZoom={11}
            center={center}
            mapId={'bf51a910020fa25a'}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            onClick={({ detail }: MapMouseEvent) => {
              const lat = detail.latLng?.lat;
              const lng = detail.latLng?.lng;
              if (lat && lng) {
                getAddressHandle({ latitude: lat, longitude: lng });
              }
            }}
            // options={mapOptions}
          >
            <AdvancedMarker ref={markerRef} position={center}>
              <MapLocationIcon
                // @ts-ignore
                lat={center.lat}
                lng={center.lng}
                style={{
                  position: 'absolute',
                  width: 48,
                  height: 55,
                  left: -48 / 2,
                  top: -55 / 2,
                }}
              />
            </AdvancedMarker>
          </Map>
          <MapHandler marker={marker} center={center} />

          <MapControl position={ControlPosition.TOP}>
            <div className='autocomplete-control'>
              <MapModalSearchInput
                onPlaceSelect={(value) => {
                  const lat = value?.geometry?.location?.lat();
                  const lng = value?.geometry?.location?.lng();
                  if (lat && lng) {
                    setCenter({ lat, lng });
                    setDescription(value?.formatted_address || '');
                  }
                }}
              />
            </div>
          </MapControl>
        </APIProvider>
      </div>
      <UIActionButton type='close' onClick={() => setOpen(false)} classes={{ root: 'map-modal__close' }} />

      <div className='map-modal__content'>
        <h2 className='map-modal__address'>{description}</h2>
        <div className='map-modal__wrapper'>
          <UIButton
            text='Use My Location'
            color='secondary'
            className='map-modal__use-my-location'
            onClick={fetchUserLocation}
            disabled={mapLoading}
            loading={mapLoading}
          />
          <UIButton
            text='Confirm Address'
            color='primary'
            className='map-modal__confirm'
            onClick={handleConfirm}
            disabled={!description || mapLoading}
          />
        </div>
      </div>
    </UIModal>
  );
};

export default React.forwardRef(MapModal);
