import React from 'react';
import './ScheduleReportStatusList.sass';
import { SchedulesModel } from 'app/models/SchedulesModel';
import classNames from 'classnames'; // Optional library to handle class names dynamically

interface Status {
  title: string;
  completed: boolean;
}

interface Props {
  data: SchedulesModel;
}

const getStatuses = (data: SchedulesModel): Status[] => [
  {
    title: 'I',
    completed: !!data.isInitialReportFilled,
  },
  {
    title: 'M',
    completed: !!data.isMidDayReportFilled,
  },
  {
    title: 'F',
    completed: !!data.isFinalReportFilled,
  },
  {
    title: 'A',
    completed: !!data.isAdditionalReportFilled,
  },
];

const ScheduleReportStatusList: React.FC<Props> = ({ data }) => {
  const statuses = getStatuses(data);

  return (
    <div className='report__status'>
      {statuses.map((item) => (
        <div
          key={item.title}
          className={classNames('report__status__item', {
            'report__status__item--success': item.completed,
          })}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default ScheduleReportStatusList;
