import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from 'shared/ui/BackButton/BackButton';
import SingleDetail from 'shared/ui/SingleDetail/SingleDetail';
import { getSchedule, setCurrentSchedule } from 'store/slices/schedules';

import Attendance from './components/Attendance/Attendance';

import { ReactComponent as Briefcase } from 'assets/icons/briefcase.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';

import { schedulesService } from 'api';
import { ForemanReportModel } from 'app/models/ForemanReportModel';

import UITitle from 'shared/ui/UITitle/UITitle';
import ForemanDailyReport from './components/DailyReport/DailyReport';
import './ScheduleDetailsPageRedesign.sass';
import UIButton from 'shared/ui/UIButton/UIButton';
import { CircularProgress } from '@mui/material';
import cn from 'app/helpers/cn';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { notify } from 'app/helpers/notify';
type foremanReportType = {
  title: string;
  type: string;
  data: ForemanReportModel | null;
};
const foremanReports: foremanReportType[] = [
  {
    title: 'Initial report',
    type: 'initialReport',
    data: null,
  },
  // {
  //   title: 'Pre-shift task initial report',
  //   type: 'preShiftTaskInitialReport',
  //   data: null,
  // },
  {
    title: 'Mid-day report',
    type: 'midDayReport',
    data: null,
  },
  {
    title: 'Additional report',
    type: 'additionalReport',
    data: null,
  },
  {
    title: 'Final report',
    type: 'finalReport',
    data: null,
  },
  // {
  //   title: 'Pre-shift task final report',
  //   type: 'preShiftTaskFinalReport',
  //   data: null,
  // },
];
const ScheduleDetailsPageRedesign = () => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const { id } = useParams() as { id: string };
  const { currentSchedule } = useAppSelector((state) => state.schedules);
  const dispatch = useAppDispatch();
  const [reports, setReports] = useState<foremanReportType[]>([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDailyReport = async (unique: number) => {
    try {
      setLoading(true);

      const { data } = await schedulesService.getForemanReports(unique);
      const sortedReports = foremanReports.map((item) => ({
        ...item,
        data: data.data.reports.find((rep) => rep.typeId == item.type) ?? null,
      }));
      setReports(sortedReports);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!id) return;

    dispatch(getSchedule({ id: +id }));
    getDailyReport(+id);
    // return () => {
    //   dispatch(setCurrentSchedule(undefined));
    // };
  }, [dispatch, id]);

  const downloadDetailsPage = () => {
    setIsGeneratingPDF(true);
    setTimeout(async () => {
      if (pdfRef.current) {
        const input = pdfRef.current;

        const canvas = await html2canvas(input);
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF('p', 'mm', 'a4', true);

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // Adjust these values for horizontal padding and vertical margin
        const horizontalPadding = 20; // 20mm padding on left/right
        const topMargin = 10; // Top margin (10mm)
        const bottomMargin = 10; // Bottom margin (10mm)

        // Calculate the image size and maintain aspect ratio
        const availableWidth = pdfWidth - 2 * horizontalPadding; // Width minus padding
        const availableHeight = pdfHeight - topMargin - bottomMargin; // Height minus margins

        // Maintain aspect ratio
        const ratio = Math.min(availableWidth / imgWidth, availableHeight / imgHeight);
        const finalImgWidth = imgWidth * ratio;
        const finalImgHeight = imgHeight * ratio;

        // Calculate positions (centering the image horizontally, and applying the top margin)
        const imgX = (pdfWidth - finalImgWidth) / 2;
        const imgY = topMargin; // Start 10mm from the top

        // Add image to PDF with padding and no extra vertical free space
        pdf.addImage(imgData, 'PNG', imgX, imgY, finalImgWidth, finalImgHeight);

        const fileName = currentSchedule?.project?.toLocaleLowerCase().replace(/\s+/g, '_') || 'file';

        pdf.save(`${fileName}.pdf`);
        setIsGeneratingPDF(false);
      }
    }, 100);
  };
  function copyLinkToClipboard(uuid: string) {
    const currentURL = window.location.href;

    const urlParts = currentURL.split('/');
    urlParts.pop();

    const newURL = `${urlParts.join('/')}/shared/${uuid}`;

    navigator.clipboard
      .writeText(newURL)
      .then(() => {
        console.log('Updated link copied to clipboard!');
        notify('Schedule link copied to clipboard!', { type: 'success', position: 'top-right' });
      })
      .catch((error) => {
        console.error('Failed to copy the link: ', error);
      });
  }

  console.log('redesigncurrentSchedule: ', currentSchedule);
  return (
    <div className='schedule-details-page' ref={pdfRef}>
      {!isGeneratingPDF && <BackButton label='View Schedule Details' />}
      {/* <BackButton label='View Schedule Details' destination={'/schedules?from'} /> */}
      {currentSchedule && (
        <>
          <div className='schedule-details-page__details'>
            <SingleDetail
              title='Project Address'
              className='scheldule-details-page__details--single-detail'
              icon={LocationIcon}
              label={currentSchedule?.address}
            />
            <SingleDetail
              title='Overtime'
              className='scheldule-details-page__details--single-detail'
              icon={Briefcase}
              label={currentSchedule.overtimeWorkPermitted ? 'Enabled' : 'Disabled'}
            />
            <div className='schedule-details-page__download-button-container'>
              <UIButton
                color='primary'
                text='Share'
                loading={false}
                // icon={ShareIcon}
                icon='share'
                onClick={() => copyLinkToClipboard(currentSchedule?.uuid!)}
              />
              <UIButton
                className='ui-button__fit-content schedule-details-page__download-button'
                color='white'
                icon='download'
                text='Download'
                outline
                loading={isGeneratingPDF}
                onClick={() => downloadDetailsPage()}
                // onClick={() => download(preview, fileData?.name, fileData?.extension)}
              />
            </div>

            <div className='schedule-details-page--attendance'>
              <Attendance />
            </div>
          </div>
        </>
      )}

      <div className='schedule-details-page__report'>
        <UITitle title='Reports'></UITitle>
        {loading
          ? [1, 2, 3, 4]?.map((report: any) => (
              <div
                key={report}
                style={{
                  background: 'white',
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                <ForemanDailyReport loading={true} report={report} />
              </div>
            ))
          : reports?.map((report) => (
              <div
                key={report.type}
                style={{
                  background: 'white',
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                <ForemanDailyReport loading={loading} report={report} />
              </div>
            ))}
      </div>

      {/* Download Button */}
    </div>
  );
};

export default ScheduleDetailsPageRedesign;
