import App from 'App';

import AddPayrollPage from 'pages/AddPayrollPage/AddPayrollPage';
import Chat from 'pages/Chat/Chat';
import CompaniesPage from 'pages/CompaniesPage/CompaniesPage';
import CompanyDetailsPage from 'pages/CompanyDetailsPage/CompanyDetailsPage';
import CrewDetailsPage from 'pages/CrewDetailsPage/CrewDetailsPage';
import EmployeeDetailsPage from 'pages/EmployeeDetailsPage/EmployeeDetailsPage';
import LicenseDetailsPage from 'pages/LicenseDetailsPage/LicenseDetailsPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import ProjectDetailsPage from 'pages/ProjectDetailsPage/ProjectDetailsPage';
import ProjectsPage from 'pages/ProjectsPage/ProjectsPage';
import RateDatailsPage from 'pages/RateDetailsPage/RateDetailsPage';
import CreateSchedulePage from 'pages/SchedulesPage/CreateSchedulePage/CreateSchedulePage';
import SchedulesPage from 'pages/SchedulesPage/SchedulesPage';
import ScheduleDetailsPage from 'pages/SchedulesPage/ScheduleDetailsPage/ScheduleDetailsPage';
import StaffPage from 'pages/StaffPage/StaffPage';
import React from 'react';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from './history';
import { PrivateRoute } from './PrivateRoute';
import ScheduleDetailsPageRedesign from 'pages/SchedulesPage/ScheduleDetailsPageRedesign/ScheduleDetailsPageRedesign';
import SharedSchedulesPage from 'pages/SharedSchedulesPage/SharedSchedulesPage';

const AppRoutes: React.FC = () => {
  return (
    <HistoryRouter history={history}>
      <App>
        <Routes>
          <Route element={<SharedSchedulesPage />} path='/schedules/shared/:id' />

          <Route element={<PrivateRoute component={<LoginPage />} ignore />} path='/login' />
          <Route element={<PrivateRoute component={<CompaniesPage />} />} path='/companies' />
          <Route element={<PrivateRoute component={<CompanyDetailsPage />} />} path='/companies/:id' />
          <Route element={<PrivateRoute component={<RateDatailsPage />} />} path='/companies/:id/rates/:rateId' />
          <Route element={<PrivateRoute component={<ProjectsPage />} />} path='/projects/' />
          <Route element={<PrivateRoute component={<ProjectDetailsPage />} />} path='/projects/:id' />
          <Route element={<PrivateRoute component={<SchedulesPage />} />} path='/schedules' />
          <Route element={<PrivateRoute component={<ScheduleDetailsPageRedesign />} />} path='/schedules/:id' />
          <Route element={<PrivateRoute component={<Chat />} />} path='/schedules/:id/chat' />
          <Route element={<PrivateRoute component={<CreateSchedulePage />} />} path='/schedules/create' />
          <Route element={<PrivateRoute component={<CreateSchedulePage mode='edit' />} />} path='/schedules/edit/:id' />
          <Route element={<PrivateRoute component={<StaffPage />} />} path='/staff' />
          <Route element={<PrivateRoute component={<EmployeeDetailsPage />} />} path='/employees/:id' />
          <Route element={<PrivateRoute component={<CrewDetailsPage />} />} path='/crews/:id' />
          <Route element={<PrivateRoute component={<AddPayrollPage mode='edit' />} />} path='/payrolls/edit/:id' />
          <Route element={<PrivateRoute component={<LicenseDetailsPage />} />} path='/licenses/:id' />

          {/* TODO: Backlog <Route element={<PrivateRoute component={<PayrollsPage />} />} path='/payrolls' />
          <Route element={<PrivateRoute component={<AddPayrollPage />} />} path='/payrolls/add' /> */}
        </Routes>
      </App>
    </HistoryRouter>
  );
};

export default AppRoutes;
