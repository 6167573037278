import React from 'react';

import './ScheduleItemInfo.sass';
import { Tooltip } from '@mui/material';

interface iProps {
  status: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  tooltipTexts?: any;
}
const ScheduleItemInfo = ({ status, title, Icon, tooltipTexts }: iProps) => {
  const tooltipBackground = {
    danger: '#FF4B4B',
    disabled: '#B0B8CC',
    success: '#0FB7B8',
    warning: '#FFAD33',
  }[status];

  const renderTooltipContent = () => {
    if (!Array.isArray(tooltipTexts)) return '';
    return (
      <div>
        {tooltipTexts?.map(([title, value]: any, index: number) => (
          <div
            style={{
              display: 'flex',
              gap: '20px',
              justifyContent: 'space-between',
            }}
            key={index}
          >
            <span>{title}</span>
            <span>{value}</span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className='schedule-item__table--cell'>
      <Tooltip
        PopperProps={{
          sx: {
            '.MuiTooltip-tooltip': {
              bgcolor: tooltipBackground,
            },
            '.MuiTooltip-arrow': {
              color: tooltipBackground,
            },
          },
        }}
        arrow
        placement='top'
        title={renderTooltipContent()}
      >
        <div className={`schedule-item__item schedule-item__item--${status}`}>
          <Icon className='schedule-item__icon' />
          {title}
        </div>
      </Tooltip>
    </div>
  );
};

export default ScheduleItemInfo;
