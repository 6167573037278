import { useState } from 'react';
import UISelect from 'shared/ui/UISelect/UISelect';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import _ from 'lodash';

interface MapModalSearchInputProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

interface PredictionModel {
  description: string; // Place description (can be the name or formatted address)
  place_id: string; // Place ID used to get further details
  name?: string; // Name of the place (optional initially)
  lat?: number; // Latitude (optional until details are fetched)
  lng?: number; // Longitude (optional until details are fetched)
}

const MapModalSearchInput = ({ onPlaceSelect }: MapModalSearchInputProps) => {
  const [predictions, setPredictions] = useState<PredictionModel[]>([]);

  const places = useMapsLibrary('places'); // Load 'places' library

  const searchAddress = _.debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPredictions([]); // Clear predictions initially
    if (!value) return;
    try {
      const autocompleteService = new window.google.maps.places.AutocompleteService();

      // Fetch place predictions
      autocompleteService.getPlacePredictions({ input: value }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setPredictions(
            predictions.map((prediction) => ({
              description: prediction.description, // Only description is available here
              place_id: prediction.place_id, // You need place_id to fetch further details
            }))
          );
        }
      });
    } catch (err) {
      console.log('Error fetching predictions:', err);
    }
  }, 300);

  const fetchPlaceDetails = (placeId: string | undefined) => {
    if (!places || !placeId) return;
    const service = new places.PlacesService(document.createElement('div')); // Temporary div for fet

    return new Promise<{ name: string; lat: number; lng: number }>((resolve, reject) => {
      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place && place.geometry) {
          const location = place.geometry.location;
          if (location) {
            onPlaceSelect(place);
            resolve({
              name: place.name || '', // Get the place name
              lat: location.lat(), // Get latitude
              lng: location.lng(), // Get longitude
            });
          }
        } else {
          reject(`Failed to fetch details: ${status}`);
        }
      });
    });
  };

  return (
    <div className='autocomplete-container'>
      <div className='map-modal__search'>
        <UISelect
          options={[...predictions]}
          getOptionLabel={(i) => i.description}
          placeholder='Search...'
          InputProps={{
            onChange: searchAddress,
          }}
          onChange={(_, value) => {
            fetchPlaceDetails(value?.place_id);
          }}
          className='map-modal__search-input'
        />
      </div>
    </div>
  );
};
export default MapModalSearchInput;
