import { Tooltip } from '@mui/material';
import { SchedulesModel } from 'app/models/SchedulesModel';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as CutleryIcon } from 'assets/icons/cutlery.svg';
import { ReactComponent as LoginReverseIcon } from 'assets/icons/login-reverse.svg';
import { ReactComponent as LoginIcon } from 'assets/icons/login.svg';
import ScheduleItemInfo from '../ScheduleItemInfo/ScheduleItemInfo';
import './ScheduleItemInfoList.sass';
import dayjs from 'dayjs';
interface iProps {
  data: SchedulesModel;
}

interface StatusInput {
  num: number;
  total: number;
  fail?: boolean;
  disabled?: boolean;
}

interface SetLunchInput {
  startTime: string;
  date: string;
  checkin: number;
  total: number;
}

const getStatus = ({ num, total, fail, disabled }: StatusInput) => {
  if (fail) return 'danger';
  if (disabled) return 'disabled';
  if (num == total) return 'success';
  return 'warning';
};

const getStartWorkStatus = ({ startTime, date, checkin, total }: SetLunchInput): string => {
  const dateTime = dayjs(`${date} ${startTime}`, 'DD/MM/YYYY hh:mm A');

  const scheduleStartTime = dateTime.toDate();
  const currentTime = new Date();

  return getStatus({
    num: checkin,
    total: total,
    disabled: currentTime < scheduleStartTime,
  });
};

const ScheduleItemInfoList = ({ data }: iProps) => {
  const acceptanceStatus = getStatus({
    num: data.numberOfRequestAccepted,
    total: data.numberOfEmployees,
    fail: data.hasDeniedEmployees || data.hasCancelledEmployees,
  });

  const startWorksTatus = getStartWorkStatus({
    startTime: data.startTime,
    date: data.date,
    checkin: data.numberOfCheckedIn,
    total: data.numberOfEmployees,
  });

  const lunchCheckOutStatus = getStatus({
    disabled: data.numberOfSetLunch === 0,
    num: data.numberOfSetLunch,
    total: data.numberOfEmployees,
  });

  const checkOutStatus = getStatus({
    disabled: data.numberOfCheckedOut === 0,
    num: data.numberOfCheckedOut,
    total: data.numberOfEmployees,
  });

  return (
    <div className='schedule-item__table'>
      <div className='schedule-item__table--row schedule-item__table--row-header'>
        <div className='schedule-item__table--cell schedule-item__table--cell-header'>Accepted</div>
        <div className='schedule-item__table--cell schedule-item__table--cell-header'>Check in</div>
        <div className='schedule-item__table--cell schedule-item__table--cell-header'>Set Lunch</div>
        <div className='schedule-item__table--cell schedule-item__table--cell-header'>Check out</div>
      </div>
      <div className='schedule-item__table--row'>
        <ScheduleItemInfo
          status={acceptanceStatus}
          Icon={ClockIcon}
          title={`${data.numberOfRequestAccepted}/${data.numberOfEmployees ?? 0}`}
          tooltipTexts={[
            ['Offers Accepted: ', data.numberOfRequestAccepted],
            ['Total Employees:', data.numberOfEmployees ?? 0],
          ]}
        />
        <ScheduleItemInfo
          status={startWorksTatus}
          Icon={LoginIcon}
          title={`${data.numberOfCheckedIn}/${data.numberOfEmployees ?? 0}`}
          tooltipTexts={[
            ['Employees checked in: ', data.numberOfCheckedIn],
            ['Total Employees:', data.numberOfEmployees ?? 0],
          ]}
        />
        <ScheduleItemInfo
          status={lunchCheckOutStatus}
          Icon={CutleryIcon}
          title={`${data.numberOfSetLunch}/${data.numberOfEmployees ?? 0}`}
          tooltipTexts={[
            ['Employees started lunch: ', data.numberOfSetLunch],
            ['Total Employees:', data.numberOfEmployees ?? 0],
          ]}
        />
        <ScheduleItemInfo
          status={checkOutStatus}
          Icon={LoginReverseIcon}
          title={`${data.numberOfCheckedOut}/${data.checkedOutByManagerAmount || 0}/${data.numberOfEmployees ?? 0}`}
          tooltipTexts={[
            ['Checked out employees: ', data.numberOfCheckedOut],
            ['Checked out by manager: ', data.checkedOutByManagerAmount ?? 0],
            ['Total Employees:', data.numberOfEmployees ?? 0],
          ]}
        />
      </div>
    </div>
  );
};

export default ScheduleItemInfoList;
