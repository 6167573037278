import { setInterceptors } from 'api/instance';
import history from 'app/routes/history';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { signIn } from 'store/slices/auth';
import { getFileTypesAction, getLanguages, getWebSpecialitiesAction } from 'store/slices/utils';
import { store } from 'store/store';
import { setNotificationCounter } from '../store/slices/notifications';

const authConfig = () => {
  dayjs.extend(customParseFormat);
  const token = localStorage.getItem('token') ?? '';
  const userFromLocalStorage = localStorage.getItem('user');
  const notificationCount = localStorage.getItem('notificationCount');
  const user = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;

  setInterceptors(store);

  if (token) {
    store.dispatch(signIn({ token, user }));
    store.dispatch(setNotificationCounter(notificationCount ? Number(notificationCount) : 0));
    store.dispatch(getWebSpecialitiesAction());
    store.dispatch(getFileTypesAction());
    store.dispatch(getLanguages());

    if (window.location.pathname === '/') {
      history.push('/companies');
    }
  } else {
    if (!window.location.pathname.startsWith('/schedules/shared/')) {
      history.push('/login');
    }
  }
};

export default authConfig;
