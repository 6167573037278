import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import history from 'app/routes/history';
import { FC, useEffect, useRef, useState } from 'react';
import './ProjectsPage.sass';

import AddProjectModal from 'shared/Modals/AddProjectModal/AddProjectModal';
import { AddProjectModalRef } from 'shared/Modals/AddProjectModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import Status from 'shared/ui/Status/Status';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import UITable from 'shared/ui/UITable/UITable';
import UITitle from 'shared/ui/UITitle/UITitle';

import { getProjectStatuses } from 'store/slices/projects';

import { projectsService } from 'api';
import { formatDate } from 'app/helpers/formatDate';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import qs from 'qs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import { ModalBaseRef } from 'shared/Modals/types';
import ProjectsFilterModal from './components/ProjectsFilterModal/ProjectsFilterModal';
import { ProjectsFilterModalRef } from './components/ProjectsFilterModal/types';
import { colorizeStatusProject } from './helpers';

import { ReactComponent as PlaceholderFolderIcon } from 'assets/icons/table/folder-open.svg';
import _ from 'lodash';

const limit = 12;

interface SubmitData {
  search: string;
}

const ProjectsPage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { projects, projectsLoading } = useAppSelector((state) => state.projects);

  const { control, setValue, handleSubmit } = useForm<SubmitData>();

  const addProjectModalRef = useRef<AddProjectModalRef>(null);
  const filterModalRef = useRef<ProjectsFilterModalRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);

  const [countOfFilters, setCountOfFilters] = useState(0);
  const [page, setPage] = useState(1);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    dispatch(getProjectStatuses());
  }, [dispatch]);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    setCountOfFilters(Object.keys(_.omit(params, ['page', 'search'])).length);

    setValue('search', (params.search as string) ?? '');
    setPage(params.page ? +params.page : 1);
  }, [location.search]);

  const deleteProject = (unique: number) => {
    confirmModalRef.current?.show(async () => {
      setDeleteLoading(true);
      try {
        await projectsService.deleteProject(unique);
        // filterModalRef.current?.refreshPage();
      } finally {
        setDeleteLoading(false);
        confirmModalRef.current?.hide();
      }
    });
  };

  const submit: SubmitHandler<SubmitData> = ({ search }) => {
    filterModalRef.current?.onChange({ search, page: 1 });
  };

  return (
    <div className='projects-page'>
      <UITitle
        title='Projects'
        button={{
          icon: 'plus',
          type: 'button',
          text: 'Add Project',
          onClick: () => addProjectModalRef.current?.show(),
        }}
      >
        <FormTextInput
          control={control}
          name='search'
          placeholder='Search by Address'
          InputProps={{
            endAdornment: (
              <button className='find-button' type='submit' onClick={handleSubmit(submit)}>
                <SearchIcon />
              </button>
            ),
            sx: { background: 'white', width: '304px' },
          }}
        />
        <UIButton
          count={countOfFilters}
          type='button'
          text='Filter'
          icon='filter'
          color='white'
          onClick={() => filterModalRef.current?.show()}
        />
      </UITitle>
      <UITable
        totalPages={projects.totalPages}
        data={projects?.data}
        loading={projectsLoading}
        onRowClick={(item) => {
          history.push(`/projects/${item.unique}`);
        }}
        headers={[
          // { label: 'Name' },
          { label: 'Address' },
          { label: 'Company' },
          { label: 'Last day worked' },
          { label: 'Days' },
          { label: 'Status' },
          { label: 'Actions' },
        ]}
        columns={[
          // { renderCol: ({ name }) => <div className='projects-page__table-td--name'>{name}</div> },
          {
            renderCol: ({ address }) => (
              <div className='projects-page__table-td--address'>{address[0].description}</div>
            ),
          },
          { columnName: 'company' },
          {
            renderCol: (item) => item?.lastDayWorked && formatDate({ date: item.lastDayWorked }),
          },
          { renderCol: (item) => item?.workedDays },
          {
            renderCol: ({ status }) => {
              return <Status {...colorizeStatusProject(status)} />;
            },
          },
          {
            renderCol: (item) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton
                    type='edit'
                    onClick={() => {
                      addProjectModalRef.current?.show(item);
                    }}
                  />
                  <UIActionButton type='delete' onClick={() => deleteProject(item.unique)} />
                </Stack>
              );
            },
          },
        ]}
        pagination={{
          count: projects?.totalPages ?? 0,
          page: page,
          onChange: (_, value) => {
            setPage(value);
            filterModalRef.current?.onChange({ page: value });
          },
        }}
        placeholder={{
          icon: <PlaceholderFolderIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of projects is empty. Add your first project now.',
        }}
      />
      <AddProjectModal ref={addProjectModalRef} getData={filterModalRef.current?.refreshPage} />
      <ProjectsFilterModal ref={filterModalRef} limit={limit} />
      <ConfirmModal ref={confirmModalRef} title='Delete' submitBtnText='Confirm' loading={deleteLoading}>
        Do you really want to delete this project? You will not be able to undo this.
      </ConfirmModal>
    </div>
  );
};

export default ProjectsPage;
