import { CircularProgress } from '@mui/material';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as AddIconSecondary } from 'assets/icons/plus-black.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import cn from 'classnames';
import React from 'react';
import { UIButtonDefaultProps, UIButtonIcon } from '../types';
import '../UIButton.sass';

const UIButtonContent: React.FC<UIButtonDefaultProps> = ({ icon, text, color, loading }) => {
  const renderIcon = (icon: UIButtonIcon) => {
    let Icon: typeof AddIcon = AddIcon;
    switch (icon) {
      case 'plus':
        Icon = AddIcon;
        if (color === 'secondary') Icon = AddIconSecondary;
        break;
      case 'filter':
        Icon = FilterIcon;
        break;
      case 'logout':
        Icon = LogoutIcon;
        break;
      case 'download':
        Icon = DownloadIcon;
        break;
      case 'excel':
        Icon = ExcelIcon;
        break;
      case 'share':
        Icon = ShareIcon;
        break;
      default:
        break;
    }
    return (
      <Icon
        className={cn('ui-button__icon', {
          [`ui-button__icon--${color}`]: color,
        })}
      />
    );
  };
  const render = () => {
    return (
      <>
        {icon && renderIcon(icon)}
        {text && <span className='ui-button__text'>{text}</span>}
        {loading && (
          <div className='ui-button__loader-container'>
            <CircularProgress className='ui-button__loader' value={50} />
          </div>
        )}
      </>
    );
  };
  return render();
};

export default UIButtonContent;
