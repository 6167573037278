import { useMap } from '@vis.gl/react-google-maps';
import { useEffect } from 'react';

interface MapHandlerProps {
  marker: google.maps.marker.AdvancedMarkerElement | null;
  center: { lat: number; lng: number }; // Accept center as a prop
}

const MapHandler = ({ marker, center }: MapHandlerProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !marker) return;
    const mapOptions: google.maps.MapOptions = {
      clickableIcons: false,
      styles: [
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }], // Hides road icons
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }], // Hides all icons, just in case others are triggering these requests
        },
      ],
    };

    map.setOptions(mapOptions);

    // If no place is selected, use the center prop to smoothly pan the map and update the marker
    map.panTo(new google.maps.LatLng(center.lat, center.lng)); // Smooth transition to the new center
    marker.position = new google.maps.LatLng(center.lat, center.lng); // Update marker position
  }, [map, marker, center]); // Add center to the dependency array

  return null;
};

export default MapHandler;
