import cn from 'app/helpers/cn';
import { useAppSelector } from 'app/hooks';
import React, { PropsWithChildren, useEffect } from 'react';
import HistoryManager from 'app/helpers/historyManager';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Header from 'shared/Header/Header';
import './Layout.sass';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const historyManager = HistoryManager.getInstance();
  const location = useLocation();

  const { authorized } = useAppSelector((state) => state.auth);
  const methods = useForm();

  useEffect(() => {
    let url = location.pathname;

    if (location.search) {
      url += location.search;
    }

    historyManager.addToHistory(url);
  }, [historyManager, location]);

  return (
    <FormProvider {...methods}>
      <div className='layout'>
        {authorized && !window.location.pathname.startsWith('/schedules/shared/') && <Header />}
        <div className={cn('layout__content')}>
          <div
            className={cn('layout__container container', {
              chat: location.pathname.includes('chat'),
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default Layout;
