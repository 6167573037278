import { Stack } from '@mui/material';
import cn from 'app/helpers/cn';
import { formatDate } from 'app/helpers/formatDate';
import { SchedulesModel } from 'app/models/SchedulesModel';
import history from 'app/routes/history';
import { ReactComponent as BriefcaseIcon } from 'assets/icons/briefcase-filled.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location-filled.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-filled.svg';
import { ReactComponent as OvertimeEnabled } from 'assets/icons/overtime-enabled.svg';
import { ReactComponent as OvertimeDisabled } from 'assets/icons/overtime-disabled.svg';

import { ReactComponent as ProfileIcon } from 'assets/icons/profile-filled.svg';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import Status from 'shared/ui/Status/Status';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import './ScheduleItem.sass';
import ScheduleReportStatusList from '../ScheduleReportStatusList/ScheduleReportStatusList';
import ScheduleItemInfoList from '../ScheduleItemInfoList/ScheduleItemInfoList';

interface Props {
  index: number;
  allData: SchedulesModel[];
  data: SchedulesModel;
  className?: string;
  deleteSchedule: (unique: number) => void;
  closeSchedule?: (unique: number) => void;
  hideEditBtn?: boolean;
}

const ScheduleItem: React.FC<Props> = ({
  index,
  allData,
  data,
  className = '',
  deleteSchedule,
  closeSchedule,
  hideEditBtn,
}) => {
  let groupDate = '';
  const { date } = data;
  const prevSchedule = allData[index - 1];

  if (prevSchedule) {
    const diff = dayjs(formatDate({ date })).diff(dayjs(formatDate({ date: prevSchedule.date })), 'day');

    if (!!diff) {
      groupDate = formatDate({ date });
    }
  } else {
    groupDate = formatDate({ date });
  }

  return (
    <>
      {!!groupDate.length && (
        <div className='schedule-item__date'>
          <span>{formatDate({ date, format: 'D MMM, YYYY' })}</span>
        </div>
      )}
      <Link
        to={`/schedules/${data.unique}`}
        className={cn('schedule-item', {
          [className]: className,
        })}
      >
        <div className='schedule-item__inner'>
          <div className='schedule-item__container'>
            {data.address && <div className='schedule-item__title'>{data.address}</div>}
            <Stack spacing={1.3}>
              {data.company && (
                <div className='schedule-item__item'>
                  <BriefcaseIcon className='schedule-item__icon schedule-item__icon--fill' />
                  <div className='schedule-item__text'>{data.company}</div>
                </div>
              )}
              {data.address && (
                <div className='schedule-item__item'>
                  <LocationIcon className='schedule-item__icon' />
                  <div className='schedule-item__text'>{data.address}</div>
                </div>
              )}
              {data.date && (
                <div className='schedule-item__item schedule-item__item-date'>
                  <CalendarIcon className='schedule-item__icon' />
                  <div className='schedule-item__text'>{formatDate({ date: data.date })}</div>
                </div>
              )}

              {data.overtimeWorkPermitted ? (
                <div className='schedule-item__item schedule-item__item-date'>
                  <OvertimeEnabled className='schedule-item__icon' />
                  <div className='schedule-item__text'>Overtime enabled</div>
                </div>
              ) : (
                <div className='schedule-item__item schedule-item__item-date'>
                  <OvertimeDisabled className='schedule-item__icon' />
                  <div className='schedule-item__text'>Overtime disabled</div>
                </div>
              )}
            </Stack>
            <Stack className='schedule-item__statuses' sx={{ mt: 2 }}>
              {data.requestDeniedCount && (
                <Status text={`request denied (${data.requestDeniedCount})`} color='danger' />
              )}
              {!!data.numberAccident && <Status text='accident' color='warning' />}
              {!!data.unreadMessages && <Status text={`chat (${data.unreadMessages})`} color='primary' />}
            </Stack>
          </div>
        </div>
        <div className='schedule-item__inner'>
          <div className='schedule-item__container'>
            <div className='schedule-item__row'>
              <div className='schedule-item__item schedule-item__item--flex'>
                <ClockIcon className='schedule-item__icon' />
                <div className='schedule-item__text'>{data.startTime}</div>
              </div>
              <div className='schedule-item__item schedule-item__item--flex'>
                <ProfileIcon className='schedule-item__icon schedule-item__icon--fill' />
                <div className='schedule-item__text'>{data.numberOfEmployees} workers</div>
              </div>
            </div>
          </div>
          <ScheduleItemInfoList data={data} />
          <ScheduleReportStatusList data={data} />
          <div className='schedule-item__container'>
            <div className='schedule-item__btns'>
              <UIButton
                text='Chat'
                fitContent
                color='primary'
                className='schedule-item__btn'
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  history.push(`/schedules/${data.unique}/chat`);
                }}
              />
              {closeSchedule && (
                <UIButton
                  text='Close'
                  color='danger'
                  fitContent
                  className='schedule-item__btn'
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    closeSchedule(data.unique);
                  }}
                />
              )}
              {!hideEditBtn && (
                <UIActionButton
                  type='edit'
                  classes={{ root: 'schedule-item__action-button' }}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    history.push(`/schedules/edit/${data.unique}`);
                  }}
                />
              )}
              <UIActionButton
                type='delete'
                classes={{ root: 'schedule-item__action-button' }}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  deleteSchedule(data.unique);
                }}
              />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ScheduleItem;
